<template>
  <div class="maintenanceWorkOrder">
    <div class="maintenanceWorkOrder-nav">
      <div
        :class="[{ active: curIndex == index }]"
        v-for="(item, index) in navList"
        :key="index"
        @click="handelSelectNav(item, index)"
      >
        <span>
          {{ item.label }}
          <img
            :class="`img_${index}`"
            v-if="curIndex == index"
            src="./img/icon.png"
            alt=""
          />
        </span>
      </div>
    </div>
    <div class="maintenanceWorkOrder-content">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="list"
          v-for="(item, index) in listData"
          :key="index"
          @click="handelClickRouter(item.id)"
        >
          <div class="oder-number">
            <div class="label">
              <span>工单编号：</span>
              <span>{{ item.serviceNumber }}</span>
            </div>
            <div class="status">{{ item.status | filterStatus }}</div>
          </div>
          <div class="label">
            <span>设备名称：</span>
            <span>{{ item.equipmentName }}</span>
          </div>
          <div class="label">
            <span>损坏说明：</span>
            <span>{{ item.malfunctionDescription }}</span>
          </div>
          <div class="label">
            <span>发起时间：</span>
            <span>{{ item.applyTime }}</span>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { getMyServiceWorkList } from "./api.js";
export default {
  name: "maintenanceWorkOrder",
  components: {},
  props: {},
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      curIndex: 0,
      requestData: {
        curPage: 1,
        pageSize: 10,
        status: null,
        phoneNumber: this.$store.state.mobile,
      },
      listData: [],
      navList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "待接单",
          value: 1,
        },
        {
          label: "待服务",
          value: 2,
        },
        {
          label: "待审核",
          value: 4,
        },
        {
          label: "已完成",
          value: 5,
        },
      ],
    };
  },
  created() {},
  mounted() {},
  filters: {
    filterStatus(status) {
      const obj = {
        // 工单状态: 1已受理-2待服务-3服务中-4待反馈(已处理)-5已反馈(待审核)-6已完成（审核通过）
        1: "待接单",
        2: "待服务",
        4: "待审核",
        5: "已完成",
      };
      return obj[status];
    },
  },
  methods: {
    //选择nav
    handelSelectNav(item, index) {
      this.curIndex = index;
      this.requestData.status = item.value;
      this.requestData.curPage = 1;
      this.listData = [];
      this.onLoad();
    },
    //跳转详情
    handelClickRouter(id) {
      this.$router.push({
        name: "workOrderServeDetail",
        query: {
          id,
        },
      });
    },
    onLoad() {
      this.getMyServiceWorkList();
    },
    getMyServiceWorkList() {
      this.$axios
        .get(getMyServiceWorkList, { params: this.requestData })
        .then((res) => {
          if (res.code === 200) {
            if (this.requestData.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (this.requestData.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.requestData.curPage++;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.maintenanceWorkOrder {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 18px;
  .maintenanceWorkOrder-nav {
    position: sticky;
    top: 18px;
    width: 100%;
    height: 132px;
    padding: 84px 54px 20px;
    background: #ffffff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      flex: 1;

      &.active {
        span {
          color: #333333;
          font-size: 32px;
          line-height: 44px;
          font-weight: 600;
        }
      }
      text-align: center;
      span {
        position: relative;
        display: inline-block;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 40px;
      }
      img {
        width: 28px;
        height: 28px;
        position: absolute;
        top: -30%;
        right: -20%;
      }
      .img_0 {
        top: -30%;
        right: -30%;
      }
    }
  }
  .maintenanceWorkOrder-content {
    width: 100%;
    height: calc(100% - 112px);
    overflow: hidden;
    overflow-y: scroll;
    background-color: #f8f8f8;
    box-sizing: border-box;
    padding: 20px 30px;
    .list {
      width: 100%;
      min-height: 284px;
      background: #ffffff;
      border-radius: 16px;
      padding: 30px 30px 30px 36px;
      box-sizing: border-box;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .oder-number {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .status {
          flex-shrink: 0;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2b3857;
          line-height: 40px;
          &.status-1 {
            color: #ff9b55;
          }
          &.status-2 {
            color: #4b85fe;
          }
          &.status-3 {
            color: #bdbdbd;
          }
        }
      }
      .label {
        display: flex;
        align-items: flex-start;
        span {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 56px;
          &:first-child {
            flex-shrink: 0;
          }
          &:last-child {
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
  }
}
</style>
